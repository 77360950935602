import React from 'react';
import { EnvironmentOutlined, CalendarOutlined, ShopOutlined } from '@ant-design/icons';
import moment from 'moment';
import styles from './hero.module.css';
import EnrollButton from '../enroll/enrollButton';

export interface HeroProps {
  title: string,
  backgroundImage: string,
  location?: string,
  date: Date,
  registerLabel: string,
  companyCount?: number,
};

const Hero = ({ title, location, date, backgroundImage, companyCount }: HeroProps) => {
  return (
    <section className={styles.hero} style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div className={`container ${styles.heroContainer}`}>
        <h1 data-title={title}>
          <span>{title}</span>
        </h1>

        <EnrollButton classNames={{ root: styles.enrollButtons, countdown: styles.countdown }} />

        {date &&
          <ul>
            <li>
              <EnvironmentOutlined title="Locatie" className={styles.icon} />
              <span>{location}</span>
            </li>

            <li>
              <CalendarOutlined title="Datum" className={styles.icon} />
              <span>{moment(date).format('DD-MM-YYYY')}</span>
            </li>

            {companyCount && companyCount > 0 && <li>
              <ShopOutlined title="Bedrijven" className={styles.icon} />
              <span><strong>176</strong> bedrijven</span>
            </li>}
          </ul>
        }
      </div>
    </section>
  );
};

export default Hero;
